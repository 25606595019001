var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v(" Filtros ")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"PN","label-for":"searchConsultant"}},[_c('v-select',{attrs:{"id":"searchConsultant","multiple":"","reduce":function (consultant_list) { return consultant_list.id; },"options":_vm.consultants,"loading":_vm.loading.consultants,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.consultant),callback:function ($$v) {_vm.consultant=$$v},expression:"consultant"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Forma de aplicação","label-for":"searchCreditType"}},[_c('v-select',{attrs:{"id":"searchCreditType","reduce":function (credit_type) { return credit_type.key; },"options":_vm.creditTypes,"loading":_vm.loading.creditTypes,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.creditType),callback:function ($$v) {_vm.creditType=$$v},expression:"creditType"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Motivo","label-for":"searchAdjustmentReason"}},[_c('v-select',{attrs:{"id":"searchAdjustmentReason","multiple":"","reduce":function (reasons) { return reasons.id; },"options":_vm.adjustmentReasons,"loading":_vm.loading.adjustmentReasons,"label":"name"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.adjustmentReason),callback:function ($$v) {_vm.adjustmentReason=$$v},expression:"adjustmentReason"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Período","label-for":"searchRangeDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"searchRangeDate","config":_vm.flatPickrConfig,"placeholder":("01 Jan 2020 até 31 Dez " + _vm.actualYear)},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }