<template>
    <div>
      <b-card-header class="pb-50">
        <h5>
          Filtros
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <b-form-group
              label="PN"
              label-for="searchConsultant"
            >
              <v-select
                id="searchConsultant"
                multiple
                v-model="consultant"
                :reduce="consultant_list => consultant_list.id"
                :options="consultants"
                :loading="loading.consultants"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Forma de aplicação"
              label-for="searchCreditType"
            >
              <v-select
                id="searchCreditType"
                v-model="creditType"
                :reduce="credit_type => credit_type.key"
                :options="creditTypes"
                :loading="loading.creditTypes"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Motivo"
              label-for="searchAdjustmentReason"
            >
              <v-select
                id="searchAdjustmentReason"
                multiple
                v-model="adjustmentReason"
                :reduce="reasons => reasons.id"
                :options="adjustmentReasons"
                :loading="loading.adjustmentReasons"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-1"
          >
            <b-form-group
              label="Período"
              label-for="searchRangeDate"
            >
              <flat-pickr
                id="searchRangeDate"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </div>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormDatepicker,
    BFormRadioGroup, BFormRadio, BButton, BSpinner, BPopover, BCardText
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { mapActions, mapGetters } from 'vuex'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'
  import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
  import todayButton from '@/helpers/todayButton'
  import * as types from '../store/types'
  import * as sharedTypes from '@/modules/shared/store/types'
  import * as accountTypes from '@/modules/account/store/types'
  import flatPickr from 'vue-flatpickr-component'
  import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      BFormGroup,
      BFormInput,
      BFormDatepicker,
      BFormRadioGroup,
      BFormRadio,
      BButton,
      BSpinner,
      vSelect,
      BCardText,
      BPopover,
      flatPickr
    },
    props:{
      search: Function,
      spinnerActive: Boolean,
      periodType: String
    },
    data() {
      return {
        loading: {
          creditTypes: false,
          consultants: false,
          adjustmentReasons: false
        },
        flatPickrConfig:{
          altFormat: 'j M Y',
          altInput: true,
          mode: 'range',
          dateFormat: 'Y-m-d',
          locale: Portuguese,
          plugins:[
            ShortcutButtonsPlugin(todayButton)
          ]
        },
        creditType: undefined,
        consultant: undefined,
        adjustmentReason: undefined,
        rangeDate: undefined,
        dir: 'ltr'
      }
    },
    setup() {
      const actualYear = new Date().getFullYear()
      return { toast: useToast(), actualYear };
    },
    computed: {
      ...mapGetters({
        consultants: sharedTypes.CONSULTANTS_PARTNER,
        creditTypes: sharedTypes.CREDIT_TYPES,
        adjustmentReasons: types.FINANCIAL_ADJUSTMENT_REASONS,
        initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
        itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
        user: accountTypes.USER
      }),
    },
    mounted() {
      this.loading.consultants = true
      this.getConsultants()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultants = false
        })
      this.loading.creditTypes = true
      this.getCreditTypes()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os tipos de crédito para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.creditTypes = false
        })
      this.loading.adjustmentReasons = true
      this.getAdjustmentReasons()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os motivos para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.adjustmentReasons = false
        })
    },
    methods: {
      ...mapActions({
        getConsultants: sharedTypes.GET_CONSULTANTS_PARTNER,
        getCreditTypes: sharedTypes.GET_CREDIT_TYPES,
        getAdjustmentReasons: types.GET_FINANCIAL_ADJUSTMENT_REASONS
      }),
    }
  }
  </script>
  
  <style lang="scss">
  $themeColor: #f26237;
  @import '~vue-slider-component/lib/theme/default.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .form-group {
    margin-bottom: 0;
  }
  </style>
  