<template>
  <div>
    <b-sidebar
      id="sidebar-withdrawal-request"
      sidebar-class="sidebar-lg"
      :visible="saveFinancialAdjustmentSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateSaveFinancialAdjustmentSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Lançamento financeiro</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <label for="name">Descrição</label>
              <b-form-input
                v-model="name"
                type="text"
                :class="{ 'is-invalid': v$.name.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.name.required.$invalid">
                  Você deve informar uma descrição para este lançamento
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Valor" label-for="amount">
                <b-form-input
                  id="amount"
                  v-money="moneyFormat"
                  v-model="amount"
                  :class="{ 'is-invalid': v$.amount.$error }"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.amount.required.$invalid || v$.amount.maxValue.$invalid
                    "
                  >
                    Você deve informar um valor válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Data do lançamento" label-for="launch-date">
                <b-form-input
                  id="launch-date"
                  v-model="launchDate"
                  type="date"
                  :class="{ 'is-invalid': v$.launchDate.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.launchDate.required.$invalid">
                    Você deve informar a data do lançamento
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <dynamic-select
                id="consultant"
                label="Quem receberá o lançamento?"
                placeholder="Digite o nome..."
                v-model="consultant"
                :options="consultants"
                :loading="loading.consultants"
                @find="findConsultants"
                :thereIsError="v$.consultant.$error"
                errorMessage="Você deve informar quem receberá o lançamento"
                @input="consultantChanged"
              />
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Tipo" label-for="type">
                <v-select
                  id="type"
                  v-model="type"
                  :reduce="(type) => type.key"
                  :options="types"
                  :loading="loading.types"
                  label="name"
                  :class="getSelectErrorClass(v$.type.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.type.required.$invalid">
                    Você deve informar o tipo do lançamento
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Aplicação do valor" label-for="credit-type">
                <v-select
                  id="credit-type"
                  v-model="creditType"
                  :reduce="(creditType) => creditType.key"
                  :options="creditTypes"
                  :loading="loading.creditTypes"
                  label="name"
                  :class="getSelectErrorClass(v$.creditType.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.creditType.required.$invalid">
                    Você deve informar a forma de aplicação do lançamento
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Motivo" label-for="type">
                <v-select
                  id="type"
                  v-model="reason"
                  :reduce="(reason) => reason.id"
                  :options="reasons"
                  :loading="loading.reasons"
                  label="name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Centro de custo" label-for="type">
                <v-select
                  id="type"
                  v-model="costCenter"
                  :reduce="(cost_center) => cost_center.id"
                  :options="costCenters"
                  :loading="loading.costCenters"
                  @input="costCenterChanged"
                  label="name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2" v-if="costCenter">
              <b-form-group label="Categoria do centro de custo" label-for="type">
                <v-select
                  id="type"
                  v-model="costCenterCategory"
                  :reduce="(category) => category.id"
                  :options="costCenterCategories"
                  :loading="loading.costCenterCategories"
                  label="name"
                  :class="getSelectErrorClass(v$.costCenterCategory.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.costCenterCategory.required.$invalid">
                    Você deve informar a categoria do centro de custo
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-if="!isEdit" cols="12" class="mt-2">
              <b-form-checkbox
                :checked="hasRecurrence"
                v-model="hasRecurrence"
                switch
                inline
              >
                Haverá recorrência deste lançamento
              </b-form-checkbox>
            </b-col>
            <b-col v-if="!isEdit && hasRecurrence" cols="12" class="mt-2">
              <label for="recurrenceInMonths">Recorrência (em meses)</label>
              <b-form-input
                v-model="recurrenceInMonths"
                type="number"
                :class="{ 'is-invalid': v$.recurrenceInMonths.$error }"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.recurrenceInMonths.required.$invalid ||
                    v$.recurrenceInMonths.between.$invalid
                  "
                >
                  A recorrência suporta valores de 1 à 12 meses
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="mt-2">
              <label for="comment">Comentário</label>
              <b-form-textarea
                id="comment"
                v-model="comment"
                class="mb-2 mb-lg-0"
              />
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
  BFormInput,
} from "bootstrap-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import MoneyInput from "@/modules/shared/components/MoneyInput";
import { VMoney } from "v-money";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { getBRLFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import _ from "lodash";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BCol,
    BRow,
    BFormInput,
    vSelect,
    DynamicSelect,
    MoneyInput,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      loading: {
        types: false,
        creditTypes: false,
        reasons: false,
        consultants: false,
        costCenters: false,
        costCenterCategories: false
      },
      saving: false,
      name: undefined,
      amount: undefined,
      type: undefined,
      launchDate: undefined,
      consultant: undefined,
      creditType: undefined,
      reason: undefined,
      hasRecurrence: false,
      recurrenceInMonths: "1",
      comment: undefined,
      costCenter: undefined,
      costCenterCategory: undefined
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required },
      amount: {
        required,
        maxValue: function (value) {
          let amount = moneyToFloat(value);
          return amount > 0;
        },
      },
      type: { required },
      launchDate: { required },
      consultant: { required },
      creditType: { required },
      reason: {},
      hasRecurrence: {},
      recurrenceInMonths: {
        required,
        between: between(1, 12),
      },
      comment: {},
      costCenter: {},
      costCenterCategory: {
        required: requiredIf(() => {
          return this.costCenter
        }) 
      }
    };
  },
  computed: {
    ...mapGetters({
      saveFinancialAdjustmentSidebar: types.SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR,
      types: types.FINANCIAL_ADJUSTMENT_TYPES,
      creditTypes: types.FINANCIAL_ADJUSTMENT_CREDIT_TYPES,
      reasons: types.FINANCIAL_ADJUSTMENT_REASONS,
      consultants: sharedTypes.CONSULTANTS,
      costCenters: sharedTypes.COST_CENTERS,
      costCenterCategories: sharedTypes.COST_CENTER_CATEGORIES
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    isEdit: function () {
      return this.saveFinancialAdjustmentSidebar?.id
    }
  },
  mounted() {
    this.loading.types = true;
    this.getFinancialAdjustmentTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de lançamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.types = false;
      });
    this.loading.creditTypes = true;
    this.getCreditTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as formas de aplicação do lançamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.creditTypes = false;
      });
    this.loading.reasons = true;
    this.getFinancialAdjustmentReasons()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os motivos do lançamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.reasons = false;
      });
    this.loading.costCenters = true;
    this.getCostCenters()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os centros de custo. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.costCenters = false;
      });
   
  },
  methods: {
    floatToMoney,
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    ...mapMutations({
      mutateSaveFinancialAdjustmentSidebar:
        types.MUTATE_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR,
    }),
    ...mapActions({
      getFinancialAdjustmentReasons: types.GET_FINANCIAL_ADJUSTMENT_REASONS,
      getCreditTypes: types.GET_FINANCIAL_ADJUSTMENT_CREDIT_TYPES,
      getFinancialAdjustmentTypes: types.GET_FINANCIAL_ADJUSTMENT_TYPES,
      saveFinancialAdjustment: types.SAVE_FINANCIAL_ADJUSTMENT,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFinancialAdjustment: types.GET_FINANCIAL_ADJUSTMENT,
      getCostCenters: sharedTypes.GET_COST_CENTERS,
      getCostCenterCategories: sharedTypes.GET_COST_CENTER_CATEGORIES
    }),
    onShow() {
      if (this.saveFinancialAdjustmentSidebar?.id) {
        this.getFinancialAdjustment(this.saveFinancialAdjustmentSidebar.id)
        .then((response) => {
            const { 
              name,
              value,
              type,
              launch_date,
              consultant_id,
              credit_type,
              financial_adjustment_reason_id,
              comment, 
              cost_center_id,
              cost_center_category_id
            } = response.data;
            this.name = name;
            this.amount = floatToMoney(value);
            this.type = type;
            this.launchDate = launch_date;
            this.consultant = consultant_id;
            this.creditType = credit_type;
            this.reason = financial_adjustment_reason_id;
            this.comment = comment;
            this.costCenter = cost_center_id;
            this.costCenterCategory = cost_center_category_id;
            this.v$.$touch();
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o lançamento para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
        this.findConsultants()
        this.fetchCostCenterCategories()
      }
    },
    findConsultants(keyword) {
      this.loading.consultants = true;
      this.getConsultants({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os usuários para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultants = false;
        });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const payload = {
        name: this.name,
        amount: moneyToFloat(this.amount),
        type: this.type,
        launch_date: this.launchDate,
        consultant_id: this.consultant,
        credit_type: this.creditType,
        recurrence_in_months: 1,
        reason: this.reason,
        comment: this.comment,
        cost_center_id: this.costCenter,
        cost_center_category_id: this.costCenterCategory
      };
      const recurrenceInMonths = parseInt(this.recurrenceInMonths);
      if (!isNaN(recurrenceInMonths)) {
        payload.recurrence_in_months = recurrenceInMonths;
      }
      if (this.saveFinancialAdjustmentSidebar?.id) {
        payload.id = this.saveFinancialAdjustmentSidebar?.id
      }
      this.saveFinancialAdjustment(payload)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Lançamento salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.saveFinancialAdjustmentSidebar.saveAction();
          this.mutateSaveFinancialAdjustmentSidebar({ visible: false });
        })
        .catch(err => {
          let message = "Ocorreu um erro ao salvar o lançamento. Entre em contato com o setor de TI.";
          if(err.response.status == 405){
            message = err.response.data.message;
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    costCenterChanged(){
      this.costCenterCategory = undefined
      this.fetchCostCenterCategories()
    },
    fetchCostCenterCategories(){
      if(!this.costCenter) return
      this.loading.costCenterCategories = true;
      this.getCostCenterCategories(this.costCenter)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as categorias de centro de custo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.costCenterCategories = false;
        });
    },
    consultantChanged(consultant_id) {
      const consultant = _.find(this.consultants, { id: consultant_id });
      if (consultant && !consultant.receives_commission) {
        this.consultant = undefined
        this.v$.consultant.$touch()
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção!",
            text: "O interessado selecionado é um colaborador que não deve receber lançamentos financeiros. Por favor, crie uma despesa.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },

    clear() {
      this.name = undefined;
      this.amount = undefined;
      this.type = undefined;
      this.launchDate = undefined;
      this.consultant = undefined;
      this.creditType = undefined;
      this.reason = undefined;
      this.hasRecurrence = false;
      this.recurrenceInMonths = 1;
      this.comment = undefined;
      this.costCenter = undefined;
      this.costCenterCategory = undefined;
      this.mutateSaveFinancialAdjustmentSidebar({ id: undefined });
      this.v$.$reset();
    },
  },
};
</script>
